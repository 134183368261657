import classNames from "classnames";
import React, { useEffect } from "react";
import { Overlay } from "../Overlay/Overlay";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";
import { TTSButton } from "../_controls/TTSButton/TTSButton";

import "./Modal.scss";

interface ModalSubComponents {
  Header: React.FC<HeaderProps>;
  Title: React.FC<TitleProps>;
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
}

interface HeaderProps {
  children?: React.ReactNode;
  onHide?: () => void;
  headerTestid?: string;
}

interface TitleProps {
  children?: string;
  ttsButtonId?: string;
  iconVisibility?: boolean;
}

interface BodyProps {
  id?: string;
  children?: React.ReactNode;
  isNoFooter?: boolean;
}

interface FooterProps {
  children?: React.ReactNode;
}

interface Props {
  show: boolean;
  children?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  autoHeight?: boolean;
  id?: string;
  modalBodyId?: string;
  tabIndex?: number;
  testId?: string;
  overlay?: boolean;
  dir?: string;
}

const Modal: React.FC<Props> & ModalSubComponents = (props) => {
  const {
    show,
    children,
    className = "",
    wrapperClassName = "",
    autoHeight = false,
    id,
    modalBodyId,
    tabIndex,
    testId,
    overlay = true,
    dir,
  } = props;
  const wrapClass = classNames(
    "modal-wrapper",
    { open: !!show },
    wrapperClassName
  );
  const bodyClass = classNames("modal", {
    [className]: !!className,
    "auto-height": autoHeight,
  });

  useEffect(() => {
    const body = document.body;
    if (show) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }

    return () => {
      body.classList.remove("modal-open");
    };
  }, [show]);

  return (
    <div
      className={wrapClass}
      id={id}
      tabIndex={tabIndex}
      data-testid={testId}
      dir={dir}
    >
      <Overlay open={overlay} />
      <div className="modal-content">
        <div className={bodyClass} id={modalBodyId}>
          {children}
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<HeaderProps> = (props) => (
  <div className="modal-header">
    {props.children}
    <button
      className="modal-header__close__btn"
      data-testid={props.headerTestid}
      onClick={props.onHide}
      {...props}
    >
      <Svg icon={SVG_ICONS.DRAWER_CLOSE} width={24} height={24} />
    </button>
  </div>
);

const Title: React.FC<TitleProps> = (props) => {
  const { children, iconVisibility = true, ttsButtonId } = props;
  return (
    <div className="modal-title">
      <Typography variant="title2">
        {children}
        {iconVisibility ? (
          <TTSButton
            value={props.children}
            className="tts-button-modal"
            id={ttsButtonId}
          />
        ) : null}
      </Typography>
    </div>
  );
};

const Body: React.FC<BodyProps> = (props) => {
  const { isNoFooter = false, id } = props;
  return (
    <div
      className={classNames("modal-body", { "no-footer": isNoFooter })}
      id={id}
      tabIndex={0}
    >
      {props.children}
    </div>
  );
};

const Footer: React.FC<FooterProps> = (props) => (
  <div className="modal-footer">{props.children}</div>
);

Modal.Header = Header;
Modal.Title = Title;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
